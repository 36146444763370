import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'comboInput',
    'comboListFrame',
    'comboSelect',
    'dateInput',
    'duplicationCheckFrame',
    'duplicationCheckResult',
    'form',
    'locationInput',
    'spotInput',
    'submitButton',
    'vacanciesFrame',
  ];

  static values = {
    locationId: String,
    comboId: String,
    combosSelectUrl: String,
    vacanciesUrl: String,
    duplicationCheckUrl: String,
  };

  connect() {
    document.addEventListener('turbo:submit-start', this.interceptFormSubmission.bind(this));
    document.addEventListener('turbo:frame-load', this.handleFrameLoad.bind(this));

    this.requireClickingSubmitButtonAgain = false;
  }

  disconnect() {
    document.removeEventListener('turbo:submit-start', this.interceptFormSubmission.bind(this));
    document.removeEventListener('turbo:frame-load', this.handleFrameLoad.bind(this));
  }

  // Values callbacks

  locationIdValueChanged(locationId, previousLocationId) {
    setTimeout(() => {
      if (locationId !== previousLocationId) {
        const url = new URL(
          `${this.combosSelectUrlValue}?location_id=${locationId}&combo_id=${this.comboIdValue}`
        );
        this.comboListFrameTarget.src = url;
      }
    }, 0);
  }

  // Targets callbacks

  locationInputTargetConnected() {
    this.locationIdValue = this.locationInputTarget.value;
    // Hidden field will not be recognized by form change event
    this.enableSubmitIfValid();
    this.clear();
  }

  spotInputTargetConnected() {
    // Clear the spot to check again after 5 minutes, in case it is no longer available
    setTimeout(() => {
      this.clear();
    }, 5 * 60 * 1000);

    if (!this.requireClickingSubmitButtonAgain) {
      this.loadDataAndSubmit();
    }
  }

  comboInputTargetConnected() {
    this.comboIdValue = this.comboInputTarget.value;
    this.comboSelectTarget.value = this.comboIdValue;
  }

  duplicationCheckResultTargetConnected() {
    this.loadDataAndSubmit();
  }

  // Input actions

  setComboId(e) {
    const value = e.target.value;
    this.comboIdValue = value;

    this.comboListFrameTarget.src = new URL(
      `${this.combosSelectUrlValue}?location_id=${this.locationIdValue}&combo_id=${this.comboIdValue}`
    );
  }

  // Actions

  handleFrameLoad(event) {
    if (event.target === this.vacanciesFrameTarget) {
      this.requireClickingSubmitButtonAgain = true;
    }
  }

  findSpot() {
    const url = new URL(`${this.vacanciesUrlValue}?${this.formSearchParams}`);
    this.vacanciesFrameTarget.src = url;
  }

  checkDuplication() {
    const url = new URL(`${this.duplicationCheckUrlValue}?${this.formSearchParams}`);
    this.duplicationCheckFrameTarget.src = url;
  }

  loadDataAndSubmit() {
    if (!this.hasSpotId) {
      this.findSpot();
    } else if (!this.hasNoDuplication) {
      this.checkDuplication();
    } else if (this.allPreconditionsMet) {
      this.formTarget.requestSubmit();
    }
  }

  interceptFormSubmission(event) {
    if (event.target !== this.formTarget || this.allPreconditionsMet) {
      return;
    }

    this.disableSubmit();
    event.detail.formSubmission.stop();
    this.loadDataAndSubmit();
  }

  disableDates(e) {
    this.application
      .getControllerForElementAndIdentifier(this.dateInputTarget, 'flatpickr')
      .disableDates(e.detail.disabledDates);
  }

  // Form status

  clear() {
    if (this.hasSpotInputTarget) {
      this.spotInputTarget.remove();
    }

    if (this.hasDuplicationCheckResultTarget) {
      this.duplicationCheckResultTarget.remove();
    }

    this.requireClickingSubmitButtonAgain = false;
  }

  disableSubmit() {
    this.submitButtonTarget.disabled = true;
  }

  enableSubmitIfValid() {
    this.submitButtonTarget.disabled = !this.formTarget.checkValidity();
  }

  // Attributes

  get formData() {
    const data = Object.fromEntries(new FormData(this.formTarget));

    const {
      'booking[date]': date,
      'booking[start_time(1i)]': year,
      'booking[start_time(2i)]': month,
      'booking[start_time(3i)]': day,
      'booking[start_time(4i)]': hour,
      'booking[start_time(5i)]': minute,
      'booking[location_id]': location_id,
      'booking[space_id]': space_id,
      'booking[spot_id]': spot_id,
      'booking[combo_id]': combo_id,
      'booking[additional_hour_id]': additional_hour_id,
      'booking[customer_attributes][id]': customer_id,
    } = data;

    return {
      date,
      year,
      month,
      day,
      hour,
      minute,
      location_id,
      space_id,
      spot_id,
      combo_id,
      additional_hour_id,
      customer_id,
    };
  }

  get formSearchParams() {
    const {
      date,
      year,
      month,
      day,
      hour,
      minute,
      customer_id,
      location_id,
      spot_id,
      combo_id,
      additional_hour_id,
      space_id,
    } = this.formData;

    return new URLSearchParams([
      ...Object.entries({
        date,
        'start_time(1i)': year,
        'start_time(2i)': month,
        'start_time(3i)': day,
        'start_time(4i)': hour,
        'start_time(5i)': minute,
        customer_id,
        location_id,
        spot_id,
        combo_id,
        additional_hour_id,
        space_id,
      }),
    ]);
  }

  get hasSpotId() {
    return this.hasSpotInputTarget && !!this.spotInputTarget.value;
  }

  get hasNoDuplication() {
    return (
      this.hasDuplicationCheckResultTarget && this.duplicationCheckResultTarget.value === 'true'
    );
  }

  get allPreconditionsMet() {
    return this.hasSpotId && this.hasNoDuplication;
  }
}

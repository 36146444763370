import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    autoReload: Boolean,
    autoReloadInterval: {
      type: Number,
      default: 60000,
    },
  };

  connect() {
    this.autoReloadInterval = setInterval(() => {
      this.reload();
    }, this.autoReloadIntervalValue);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  reload() {
    this.element.reload();
  }
}

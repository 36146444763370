import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="accommodation-extension"
export default class extends Controller {
  static targets = ['select', 'frame'];

  static values = {
    extendableUrl: String,
  };

  checkExtendable(event) {
    this.frameTarget.src = `${this.extendableUrlValue}?additional_hour_id=${event.target.value}`;
  }
}

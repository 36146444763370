import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tabs', 'tabsPanel'];
  static values = {
    defaultActive: String,
  };
  normalTabClass = 'bg-beige-50 text-beige-600 hover:bg-beige-300 hover:text-beige-900'.split(' ');
  activeTabClass = 'bg-beige-300 hover:bg-beige-300 hover:!text-beige-800 !text-beige-900'.split(
    ' '
  );

  initialize() {
    if (!this.hasTabsTarget) {
      return;
    }

    this.tabsTarget.addEventListener('click', this.onClickTabs.bind(this));
    let tab = this.tabsTarget.querySelector('button');
    if (this.defaultActiveValue) {
      tab = this.tabsTarget.querySelector(`button[data-tab-index="${this.defaultActiveValue}"]`);
    }
    this.handleTabChange(tab);
  }

  connect() {}

  disconnect() {
    if (!this.hasTabsTarget) {
      return;
    }

    this.tabsTarget.removeEventListener('click', this.onClickTabs.bind(this));
  }

  onClickTabs(e) {
    if (e.target.tagName === 'BUTTON') {
      this.handleTabChange(e.target);
    }
  }

  handleTabChange(tab) {
    const tabs = this.tabsTarget.querySelectorAll('button');
    tabs.forEach(t => {
      t.classList.remove(...this.activeTabClass);
      t.classList.add(...this.normalTabClass);
    });

    const tabIndex = tab.dataset.tabIndex;
    const panels = this.tabsPanelTarget.querySelectorAll('div[data-tabs-index]');
    const panel = this.tabsPanelTarget.querySelector(`[data-tabs-index="${tabIndex}"]`);
    tab.classList.add(...this.activeTabClass);
    tab.classList.remove(...this.normalTabClass);
    panels.forEach(p => p.classList.add('hidden'));
    panel.classList.remove('hidden');
  }
}

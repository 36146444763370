import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="menu"
export default class extends Controller {
  connect() {
    if (sessionStorage.getItem('scrollPosition')) {
      const previousScrollPosition = sessionStorage.getItem('scrollPosition');
      setTimeout(() => {
        window.scrollTo(0, previousScrollPosition);
        sessionStorage.removeItem('scrollPosition');
      }, 0);
    }

    window.addEventListener('scroll', this.saveScrollPosition);
  }

  saveScrollPosition() {
    sessionStorage.setItem('scrollPosition', window.scrollY);
  }

  disconnect() {
    window.removeEventListener('scroll', this.saveScrollPosition);
  }
}

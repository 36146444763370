import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['start', 'end'];

  initialize() {}

  connect() {
    this.validate();
  }

  get startHour() {
    return this.startTime[0];
  }

  get startMin() {
    return this.startTime[1];
  }

  get endHour() {
    return this.endTime[0];
  }

  get endMin() {
    return this.endTime[1];
  }

  get startTime() {
    const startTime = [];
    this.startTimeElement.forEach(item => startTime.push(Number(item.value)));
    return startTime;
  }

  get endTime() {
    const endTime = [];
    this.endTimeElement.forEach(item => endTime.push(Number(item.value)));
    return endTime;
  }

  get startTimeElement() {
    return this.startTarget.querySelectorAll('select');
  }

  get endTimeElement() {
    return this.endTarget.querySelectorAll('select');
  }

  get endHourElement() {
    return this.endTimeElement[0];
  }

  get endMinElement() {
    return this.endTimeElement[1];
  }

  get startHourElement() {
    return this.startTimeElement[0];
  }

  validate() {
    this.clearEndTime();
    this.disabledAllEndingHoursLessThanSelectedStartHour();
  }

  clearEndTime() {
    if (this.endHour < this.startHour) {
      this.endHourElement.value = '';
      this.endMinElement.value = '';
    }
  }

  disabledAllEndingHoursLessThanSelectedStartHour() {
    this.endHourElement.querySelectorAll('option').forEach(opt => {
      opt.removeAttribute('disabled');
      if (Number(opt.value) && Number(opt.value) <= this.startHour) {
        opt.setAttribute('disabled', true);
      }
    });
  }

  disconnect() {}
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['source', 'buttonContent', 'copiedContent'];

  static values = {
    content: String,
  };

  copy(event) {
    event.preventDefault();
    this.sourceTarget.select();
    navigator.clipboard.writeText(this.contentValue);

    this.buttonContentTarget.classList.add('hidden');
    this.copiedContentTarget.classList.remove('hidden');
    setTimeout(() => {
      this.buttonContentTarget.classList.remove('hidden');
      this.copiedContentTarget.classList.add('hidden');
    }, 2000);
  }
}

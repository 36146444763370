import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'submitButton', 'vacanciesFrame', 'spotInput', 'spotAvailability'];

  static values = {
    autoselect: Boolean,
    objectName: String,
    newRecord: Boolean,
    vacanciesUrl: String,
  };

  connect() {
    if (this.newRecordValue) {
      setTimeout(() => {
        this.findSpot();
      }, 0);
    }
  }

  spotInputTargetConnected() {
    this.interval = setInterval(() => {
      this.spotInputTarget.parentElement.remove();
      this.findSpot();
    }, 5 * 60 * 1000);

    this.disableSubmit();
    this.enableSubmitIfValid();
  }

  spotInputTargetDisconnected() {
    clearInterval(this.interval);
    this.disableSubmit();
  }

  findSpot() {
    setTimeout(() => {
      this.disableSubmit();
    }, 0);

    const { date, startHour, startMinute, endHour, endMinute } = this.formData;

    if ([date, startHour, startMinute, endHour, endMinute].some(v => !v)) {
      return;
    }

    const url = new URL(`${this.vacanciesUrlValue}?${this.formSearchParams}`);
    this.vacanciesFrameTarget.src = url;
  }

  resetAutoselect(e) {
    this.autoselectValue = !e.target.value;
  }

  // Form status

  disableSubmit() {
    this.submitButtonTarget.disabled = true;
  }

  enableSubmitIfValid() {
    const isValid = this.formTarget.checkValidity() && this.hasSpotAvailabilityTarget;
    this.submitButtonTarget.disabled = !isValid;
  }

  // Attributes

  get formData() {
    const data = Object.fromEntries(new FormData(this.formTarget));

    const objectId = data[`${this.objectNameValue}[id]`];
    const date = data[`${this.objectNameValue}[date]`];
    const startHour = data[`${this.objectNameValue}[start_time(4i)]`];
    const startMinute = data[`${this.objectNameValue}[start_time(5i)]`];
    const endHour = data[`${this.objectNameValue}[end_time(4i)]`];
    const endMinute = data[`${this.objectNameValue}[end_time(5i)]`];
    const locationId = data[`${this.objectNameValue}[location_id]`];
    const spotId = data[`${this.objectNameValue}[spot_id]`];
    const comboId = data[`${this.objectNameValue}[combo_id]`];
    const additionalHourId = data[`${this.objectNameValue}[additional_hour_id]`];

    return {
      objectId,
      date,
      startHour,
      startMinute,
      endHour,
      endMinute,
      locationId,
      spotId,
      comboId,
      additionalHourId,
    };
  }

  get formSearchParams() {
    const {
      objectId,
      date,
      startHour,
      comboId,
      additionalHourId,
      startMinute,
      endHour,
      endMinute,
      locationId,
      spotId,
    } = this.formData;

    return new URLSearchParams([
      ...Object.entries({
        [`${this.objectNameValue}_id`]: objectId,
        object_name: this.objectNameValue,
        date,
        'start_time(4i)': startHour,
        'start_time(5i)': startMinute,
        'end_time(4i)': endHour,
        'end_time(5i)': endMinute,
        location_id: locationId,
        spot_id: this.autoselectValue ? null : spotId,
        combo_id: comboId,
        additional_hour_id: additionalHourId,
      }),
    ]);
  }
}

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="future-date-time-input"
export default class extends Controller {
  static targets = ['dateInput', 'timeInput'];

  static values = {
    currentTimestamp: Number,
  };

  connect() {
    this.disablePastTimeOptions();

    this.intervalCheck = setInterval(() => {
      this.disablePastTimeOptions();
    }, 1 * 60 * 1000);
  }

  disconnect() {
    clearInterval(this.intervalCheck);
  }

  disablePastTimeOptions() {
    const { currentDate, currentHour, currentMinute } = this.currentDateTime;

    const hourOptions = this.timeInputTargets[0].querySelectorAll('option');
    const minuteOptions = this.timeInputTargets[1].querySelectorAll('option');

    hourOptions.forEach(option => {
      const optionHour = parseInt(option.value);
      if (isNaN(optionHour)) {
        return;
      }

      option.disabled = this.selectedDate <= currentDate && optionHour < currentHour;
    });

    minuteOptions.forEach(option => {
      const optionMinute = parseInt(option.value);
      if (isNaN(optionMinute)) {
        return;
      }

      option.disabled =
        this.selectedDate <= currentDate &&
        this.selectedHour <= currentHour &&
        optionMinute <= currentMinute;
    });

    [...hourOptions, ...minuteOptions].forEach(option => {
      if (option.disabled && option.selected) {
        option.selected = false;
        option.form.dispatchEvent(new Event('change'), { bubbles: true });
      }
    });
  }

  get currentDateTime() {
    const currentDate = this.currentTimestampValue
      ? new Date(this.currentTimestampValue)
      : new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();

    return { currentDate, currentHour, currentMinute };
  }

  get selectedDate() {
    const value = this.dateInputTarget.value;
    return value ? new Date(value) : null;
  }

  get selectedHour() {
    const value = this.timeInputTargets[0].value;
    return value ? parseInt(value) : null;
  }

  get selectedMinute() {
    const value = this.timeInputTargets[1].value;
    return value ? parseInt(value) : null;
  }

  get dateTimeInputsFilled() {
    return this.selectedDate != null && this.selectedHour != null && this.selectedMinute != null;
  }

  get isTimeInThePast() {
    const { currentDate, currentHour, currentMinute } = this.currentDateTime;

    if (this.selectedDate > currentDate) {
      return false;
    }

    return (
      this.selectedHour < currentHour ||
      (this.selectedHour === currentHour && this.selectedMinute <= currentMinute)
    );
  }
}

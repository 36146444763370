import { Controller } from '@hotwired/stimulus';
import tippy, { createSingleton } from 'tippy.js';

export default class extends Controller {
  static targets = ['trigger', 'content'];

  static values = {
    config: Object,
    singleton: Boolean,
  };

  initialize() {
    const config = {
      allowHTML: true,
      animation: 'shift-toward-subtle',
      arrow: false,
      interactive: true,
      placement: 'bottom',
      theme: 'chidori',
      maxWidth: null,
      trigger: 'click',
      onShown: () => {
        this.dispatch('shown');
      },
      onHide: () => {
        const shouldHide = !Boolean(this.hideCanceled);
        this.hideCanceled = false;
        return shouldHide;
      },
      ...this.configValue,
    };

    if (this.singletonValue) {
      const tippyInstances = this.triggerTargets.map((target, index) =>
        tippy(target, {
          content: this.contentTargets[index].innerHTML,
        })
      );
      this.tippy = createSingleton(tippyInstances, config);
    } else {
      if (this.hasContentTarget) {
        const content = this.contentTarget.innerHTML;
        // this.contentTarget.remove();
        this.tippy = tippy(this.triggerTarget, {
          content,
          ...config,
        });
      }
    }
  }

  disconnect() {
    if (this.tippy) {
      this.tippy.hide();
    }
  }

  hide() {
    this.tippy.hide();
  }

  hideTrigger() {
    this.triggerTarget.classList.add('hidden');
  }

  showTrigger() {
    this.triggerTarget.classList.remove('hidden');
  }

  preventHide() {
    this.hideCanceled = true;
  }
}

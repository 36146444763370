import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'submitButton'];

  connect() {
    if (this.hasFormTarget) {
      const observer = new MutationObserver(this.formItemMutation.bind(this));
      observer.observe(this.formTarget, { childList: true, subtree: true });
    }
  }

  submit(e) {
    e.preventDefault();
    e.target.closest('form').requestSubmit();
  }

  enableSubmitIfValid() {
    this.submitButtonTarget.disabled = !this.formTarget.checkValidity();
  }

  formItemMutation(mutationsList, observer) {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList') {
        this.enableSubmitIfValid();
      }
    }
  }
}

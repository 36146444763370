import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['buttonToggleMap', 'map'];

  static values = {
    disabledDates: Array,
  };

  connect() {
    this.dispatch('connect', {
      detail: {
        disabledDates: this.disabledDatesValue,
      },
    });
  }

  toggleMap() {
    const isHidden = this.mapTarget.classList.contains('hidden');

    if (isHidden) {
      this.mapTarget.classList.remove('hidden');
      this.buttonToggleMapTarget.querySelector('.arrow-icon').classList.add('rotate-180');
    } else {
      this.buttonToggleMapTarget.querySelector('.arrow-icon').classList.remove('rotate-180');
      this.mapTarget.classList.add('hidden');
    }
  }
}

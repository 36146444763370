import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['time'];
  static values = {
    startOfOperationDay: String,
    oneHourWidth: String,
  };

  initialize() {
    this.startOfOperationDay = new Date(this.startOfOperationDayValue);
  }

  connect() {
    this.setCurrentPosition();
    this.refreshLocation = setInterval(() => {
      this.setCurrentPosition();
    }, 30000);

    this.element.scrollIntoView({
      block: 'center',
      inline: 'center',
    });
  }

  setCurrentPosition() {
    const now = new Date();

    const leftPos = `calc(${(now - this.startOfOperationDay) / 3600000} * ${
      this.oneHourWidthValue
    })`;

    this.element.style.left = leftPos;

    this.timeTarget.innerHTML = `${now.getHours()}:${('0' + now.getMinutes()).slice(-2)}`;
  }

  disconnect() {
    if (this.refreshLocation) {
      clearInterval(this.refreshLocation);
    }
  }
}

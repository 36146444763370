import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';

import './controllers';

Rails.start();
ActiveStorage.start();

Rails.delegate(document, Rails.linkDisableSelector, 'turbo:before-cache', Rails.enableElement);
Rails.delegate(document, Rails.buttonDisableSelector, 'turbo:before-cache', Rails.enableElement);
Rails.delegate(document, Rails.buttonDisableSelector, 'turbo:submit-end', Rails.enableElement);

Rails.delegate(document, Rails.formSubmitSelector, 'turbo:submit-start', Rails.disableElement);
Rails.delegate(document, Rails.formSubmitSelector, 'turbo:submit-end', Rails.enableElement);
Rails.delegate(document, Rails.formSubmitSelector, 'turbo:before-cache', Rails.enableElement);

document.addEventListener('turbo:submit-end', () => {
  // Link doesn't work with delegate somehow...
  document.querySelectorAll(Rails.linkDisableSelector).forEach(Rails.enableElement);
});

import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['menu'];

  initialize() {
    this.showingMenu = false;
  }

  connect() {
    useClickOutside(this);
  }

  disconnect() {
    this.close();
  }

  open() {
    this.menuTarget.classList.remove('hidden');
    this.menuTarget.classList.add('z-20');
    this.showingMenu = true;
    this.menuTarget.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });

    this.renderOnTopIfNeeded();
  }

  renderOnTopIfNeeded() {
    const timelineCalendar = document.getElementById('timeline-calendar');
    const timelineCalendarRect = timelineCalendar.getBoundingClientRect();
    const menuRect = this.menuTarget.getBoundingClientRect();

    if (menuRect.bottom > timelineCalendarRect.bottom) {
      this.menuTarget.classList.add('bottom-9');
    }
  }

  close() {
    this.menuTarget.classList.add('hidden');
    this.menuTarget.classList.remove('z-10');
    this.showingMenu = false;
  }

  click() {
    if (this.showingMenu) {
      this.close();
    } else {
      this.open();
    }
  }

  clickOutside(event) {
    this.close();
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'occupancyPeriodFrame',
    'comboSelect',
    'additionalHourSelect',
    'startTime',
    'endTime',
  ];

  comboSelectTargetConnected() {
    if (!this.endTime) {
      this.computeEndTime();
    }
  }

  additionalHourSelectTargetConnected() {
    if (!this.endTime) {
      this.computeEndTime();
    }
  }

  reloadFrame(event) {
    const locationId = event.target.value;
    const src = this.occupancyPeriodFrameTarget.src;
    const url = new URL(src);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set('location_id', locationId);
    searchParams.set('combo_id', this.comboSelectTarget.value);
    searchParams.set('additional_hour_id', this.additionalHourSelectTarget.value);
    searchParams.set('start_time', this.startTime);

    this.occupancyPeriodFrameTarget.src = src.replace(url.search, `?${searchParams.toString()}`);
  }

  changeStartTime() {
    const endTimeUpdated = this.computeEndTime();

    if (!endTimeUpdated) {
      this.onChange();
    }
  }

  changeEndTime() {
    this.onChange();
  }

  computeEndTime() {
    const duration = this.selectedComboDuration + this.selectedAdditionHourDuration;

    if (!duration) return false;

    const endTime = new Date(this.startTime.getTime() + duration);

    if (endTime.getDate() > this.startTime.getDate()) {
      this.endHourTarget.value = '';
      this.endMinuteTarget.value = '';
    } else {
      this.endHourTarget.value = this.pad(endTime.getHours());
      this.endMinuteTarget.value = this.pad(endTime.getMinutes());
    }

    this.onChange();

    return true;
  }

  onChange() {
    this.dispatch('change');
  }

  resetAdditionHour() {
    this.additionalHourSelectTarget.value = '';
  }

  resetEndTime() {
    const defaultDuration = 1;
    this.setEndTime(defaultDuration);
  }

  // Formatter

  pad(number) {
    return number.toString().padStart(2, '0');
  }

  hoursToMilliseconds(hours) {
    return hours * 60 * 60 * 1000;
  }

  // Attributes

  get startHourTarget() {
    return this.startTimeTargets[0];
  }

  get startMinuteTarget() {
    return this.startTimeTargets[1];
  }

  get endHourTarget() {
    return this.endTimeTargets[0];
  }

  get endMinuteTarget() {
    return this.endTimeTargets[1];
  }

  get startTime() {
    const startHour = Number(this.startHourTarget.value);
    const startMinute = Number(this.startMinuteTarget.value);

    if (startHour == null && startMinute == null) return null;

    const startTime = new Date();
    startTime.setHours(startHour);
    startTime.setMinutes(startMinute);

    return startTime;
  }

  get endTime() {
    const endHour = Number(this.endHourTarget.value);
    const endMinute = Number(this.endMinuteTarget.value);

    if (!endHour && !endMinute) return null;

    const endTime = new Date();
    endTime.setHours(endHour);
    endTime.setMinutes(endMinute);

    return endTime;
  }

  get selectedComboOption() {
    if (!this.hasComboSelectTarget) return null;

    return this.comboSelectTarget.selectedOptions[0];
  }

  get selectedComboDuration() {
    if (!this.selectedComboOption) return 0;

    const duration = Number(this.selectedComboOption.dataset.durationHours) || 0;
    return this.hoursToMilliseconds(duration);
  }

  get selectedAdditionHourOption() {
    if (!this.hasAdditionalHourSelectTarget) return null;

    return this.additionalHourSelectTarget.selectedOptions[0];
  }

  get selectedAdditionHourDuration() {
    if (!this.selectedAdditionHourOption) return 0;

    const duration = Number(this.selectedAdditionHourOption.dataset.durationHours) || 0;
    return this.hoursToMilliseconds(duration);
  }
}

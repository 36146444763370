import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['spinner', 'frame'];

  static values = {
    phone: String,
    path: String,
  };

  initialize() {
    this.initialized = false;
  }

  connect() {
    document.addEventListener('turbo:frame-load', event => {
      if (event.target === this.frameTarget) {
        this.spinnerTarget.classList.add('hidden');
      }
    });
  }

  phoneValueChanged() {
    (this.initialized || this.phoneValue) && this.load();
    this.initialized = true;
  }

  changePhoneInput(event) {
    this.phoneValue = event.target.value;
  }

  load() {
    this.spinnerTarget.classList.remove('hidden');

    this.frameTarget.src = this.pathValue + '?phone=' + this.phoneValue;
  }
}

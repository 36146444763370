import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { interval: { type: Number, default: 5000 } };

  connect() {
    this.restoreScrollPosition();

    this.interval = setInterval(() => {
      this.reload();
    }, this.intervalValue);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  storeScrollPosition() {
    window.sessionStorage.setItem(
      'scrollPosition',
      window.scrollY || document.documentElement.scrollTop
    );
  }

  restoreScrollPosition() {
    const scrollPosition = window.sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition, 10));
    }
  }

  reload() {
    this.storeScrollPosition();
    window.location.reload();
  }
}

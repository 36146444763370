import Flatpickr from 'stimulus-flatpickr';
import { Vietnamese } from 'flatpickr/dist/l10n/vn.js';
import English from 'flatpickr/dist/l10n/default.js';

export default class extends Flatpickr {
  static values = {
    locale: String,
  };

  initialize() {
    const locale = this.localeValue === 'vi' ? Vietnamese : English;

    this.config = {
      locale: locale,
    };
  }

  connect() {
    super.connect();
  }

  disableDates(disabledDates) {
    this.fp.set('disable', disabledDates);

    if (disabledDates.includes(this.inputTarget.value)) {
      this.fp.clear();
    }
  }

  open() {
    this.dispatch('interacting');
  }

  monthChange() {
    this.dispatch('interacting');
  }

  yearChange() {
    this.dispatch('interacting');
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    startTime: String,
    endTime: String,
    status: String,
    displayText: String,
    staffCreated: Boolean,
    cleaningTime: Number,
  };
  static targets = ['button', 'text'];

  initialize() {
    this.startTime = new Date(this.startTimeValue);
    this.endTime = new Date(this.endTimeValue);
  }

  connect() {
    this.setToInUseText();
    this.startRefreshingText();
  }

  disconnect() {
    this.stopRefreshingText();
  }

  setToInUseText() {
    if (this.statusValue !== 'confirmed') {
      this.stopRefreshingText();
      return;
    }

    const currentTime = new Date().getTime();
    if (this.startTime.getTime() < currentTime && currentTime < this.endTime.getTime()) {
      const isCleaning = currentTime >= this.endTime.getTime() - this.cleaningTimeValue;
      const inProgressText = isCleaning ? 'Đang dọn dẹp' : 'Đang sử dụng';

      const inUseText = [this.displayTextValue.replace('Staff', ''), inProgressText]
        .filter(_ => _ != null && _ !== '')
        .join(' • ');

      this.textTarget.innerText = inUseText;
      this.textTarget.title = inUseText;

      if (isCleaning) this.buttonTarget.classList.add('stripe');
    } else {
      this.setBackToOriginal();
    }
  }

  setBackToOriginal() {
    this.textTarget.innerText = this.displayTextValue;
    this.textTarget.title = this.displayTextValue;
    this.buttonTarget.classList.remove('stripe');
  }

  startRefreshingText() {
    this.refreshTimer = setInterval(() => {
      this.setToInUseText();
    }, 60000);
  }

  stopRefreshingText() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="order-item-form"
export default class extends Controller {
  static targets = ['total', 'clearButton', 'submitButton'];

  static values = {
    unitPrice: Number,
  };

  countChanged(event) {
    const quantity = event.detail.count;
    this.updateTotal(quantity);

    if (quantity === 0) {
      this.showClearButton();
    } else {
      this.showSubmitButton();
    }
  }

  updateTotal(quantity) {
    this.totalTarget.innerHTML = vndMoneyNumber(quantity * this.unitPriceValue);
  }

  showClearButton() {
    this.clearButtonTarget.classList.remove('!hidden');
    this.submitButtonTarget.classList.add('!hidden');
  }

  showSubmitButton() {
    this.clearButtonTarget.classList.add('!hidden');
    this.submitButtonTarget.classList.remove('!hidden');
  }
}

const vndMoneyNumber = num => {
  return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ₫';
};

// From https://github.com/excid3/tailwindcss-stimulus-components/blob/master/src/modal.js

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['root', 'container'];
  static values = {
    autoOpen: Boolean,
    delay: Number,
    persistent: Boolean,
  };

  connect() {
    if (this.autoOpenValue) {
      this.open();
    }
  }

  disconnect() {
    this.close();
  }

  open() {
    const execute = () => {
      // Unhide the modal
      this.containerTarget.classList.remove('hidden');
      this.containerTarget.scrollTo(0, 0);

      // Prevent scroll of body behind at current scroll position
      this.scrollTop = document.scrollingElement.scrollTop;
      document.body.classList.add('overflow-hidden', 'w-full', 'max-h-screen');
      document.body.style.top = `-${this.scrollTop}px`;
    };

    if (this.delayValue && this.delayValue !== 0) {
      setTimeout(() => {
        execute();
      }, this.delayValue);
    } else {
      execute();
    }

    const allBackgrounds = document.querySelectorAll('.modal-background');
    allBackgrounds.forEach((bg, index) => {
      if (index === 0) {
        bg.style.opacity = 0.7;
      }
      if (index === 1) {
        bg.style.opacity = 0.6;
      }
      if (index > 1) {
        bg.style.opacity = 0.5;
      }
    });
  }

  close() {
    if (this.persistentValue) return;

    this.rootTarget.remove();

    // Unlock scroll of body behind if no modals left
    if (document.querySelectorAll('[data-modal-target="root"]').length === 0) {
      document.body.classList.remove('overflow-hidden', 'w-full', 'max-h-screen');
      if (this.scrollTop != 0) {
        document.body.style.top = '';
        document.scrollingElement.scrollTo(0, this.scrollTop);
        this.scrollTop = 0;
      }
    }
  }

  submitAndClose(e) {
    e.preventDefault();
    e.target.closest('form').requestSubmit();
    this.close();
  }

  closeWithKeyboard(e) {
    const allModals = document.querySelectorAll('[data-modal-target="root"]');
    const indexOfThisModal = Array.prototype.indexOf.call(allModals, this.rootTarget);

    if (
      e.code === 'Escape' &&
      indexOfThisModal === allModals.length - 1 && // Close the last one only
      !e.target.classList.contains('filepond--action-edit-item') // Ignore image edit escape
    ) {
      this.close();
      e.stopPropagation();
    }
  }
}

import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="counter"
export default class extends Controller {
  static targets = ['countInput', 'countDisplay', 'addButton', 'subtractButton'];

  static values = {
    count: Number,
  };

  countValueChanged() {
    this.updateDisplay();
    this.dispatch('changed', { detail: { count: this.countValue } });
  }

  add() {
    this.countValue += 1;
  }

  subtract() {
    const nextValue = this.countValue - 1;

    if (nextValue < 0) return;

    this.countValue = nextValue;
  }

  updateDisplay() {
    this.countInputTarget.value = this.countValue.toString();
    this.countDisplayTarget.innerHTML = this.countValue.toString();
  }
}

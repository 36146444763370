import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['onlinePaymentDescription', 'submitButton'];

  static values = {
    paymentMethod: String,
    cashSubmitText: String,
    onlineSubmitText: String,
  };

  connect() {
    this.changePaymentMethod({
      params: { method: this.paymentMethodValue },
    });
  }

  toggleOnlinePaymentDescription(method) {
    if (!method || (method !== 'bank' && method !== 'qr')) {
      this.onlinePaymentDescriptionTarget.classList.add('hidden');

      return;
    }

    this.onlinePaymentDescriptionTarget.classList.remove('hidden');
  }

  updateSubmitButtonText(method) {
    if (method === 'cash') {
      this.submitButtonTarget.value = this.cashSubmitTextValue;
    } else if (method === 'bank' || method === 'qr') {
      this.submitButtonTarget.value = this.onlineSubmitTextValue;
    }
  }

  changePaymentMethod(event) {
    const { method } = event.params;

    this.toggleOnlinePaymentDescription(method);
    this.updateSubmitButtonText(method);
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.hide();
  }

  connect() {
    setTimeout(() => {
      this.show();
    }, 0);
  }

  close() {
    this.hide();
    setTimeout(() => {
      this.element.remove();
    }, 500);
  }

  show() {
    this.element.classList.remove('ease-in', '-translate-y-36');

    setTimeout(() => {
      this.close();
    }, 10000);
  }

  hide() {
    this.element.classList.add('ease-out', '-translate-y-36');
  }
}
